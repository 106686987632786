import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import '@/util/share'
import '@shared/util/polyfill'
import overTip from '@qs-plus/directive/overTip'
import { logError } from '@shared/util/bi/log'
import { registerRouter } from '@common/router/index'
import { PxBiConfig, PxPerformanceBiLogger } from '@common/api/px/bi'
import App from '@/entry/AppClient.vue'
import toaster from '@/plugin/toaster'
import '@common/plugin/upload'
import router, { route } from '@/router/client/index'

registerRouter(route, router)
const app = createApp(App)

app.use(createPinia())
app.use(createHead())
app.use(router)
app.use(toaster)
app.use(overTip)

app.mount('#app')
app.config.errorHandler = err => logError(err)

new PxPerformanceBiLogger().log({
  biz: PxBiConfig.Biz,
  appName: PxBiConfig.Apps.Mall,
  pageName: route.value.meta.title ?? route.value.name,
})
